<template>
  <div class="update-user">
    <el-card class="box-card" v-loading="loading" v-if="!loading">
      <el-form
        :model="InfoForm"
        ref="InfoForm"
        label-position="top"
        :rules="rules"
      >
        <el-row :gutter="15">
          <el-col :span="24">
            <el-image
              style="width: 70px; height: 70px"
              :src="user_info['headimgurl']"
              class="avatar"
            >
            </el-image>
          </el-col>
        </el-row>
        <!--头像完成-->
        <el-form-item label="昵称" prop="nickname">
          <el-input
            type="text"
            v-model="InfoForm.nickname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--昵称完成-->
        <el-form-item label="密码" prop="passWd">
          <el-input
            type="password"
            v-model="InfoForm.passWd"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--密码完成-->
        <el-form-item label="邮箱" prop="email">
          <el-input
            type="text"
            v-model="InfoForm.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--邮箱完成-->
        <el-form-item label="邮箱验证码" prop="email_code" v-if="ShowEmailCode">
          <el-row :gutter="15">
            <el-col :span="16">
              <el-input
                type="text"
                v-model="InfoForm.email_code"
                autocomplete="off"
              ></el-input>
            </el-col>
            <el-col :span="8">
              <el-button
                @click="get_email_verify_code"
                :disabled="!InfoForm.email || email_code_wait > 0"
                >{{ EmailBtnText }}</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
        <!--邮箱验证完成-->
        <el-form-item>
          <el-button class="save-btn" type="success" @click="save"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { update_user_info } from "@/api/auth"
export default {
  data() {
    return {
      loading: true,
      email_code_wait: 0,
      InfoForm: {
        //用户信息表
        nickname: undefined,
        email: undefined,
        passWd: undefined,
        email_code: undefined,
        email_token:undefined
      },
    };
  },
  methods: {
    ...mapActions("auth", ["getUserInfo", "getEmailVerifyCode"]),
    count_down(callback, time) {
      //倒计时
      if (time < 0) {
        return 0;
      }
      setTimeout(() => {
        callback(time);
        this.count_down(callback, --time);
      }, 1000);
    },
    get_email_verify_code() {
      this.getEmailVerifyCode(this.InfoForm.email).then((data) => {
        this.$message.success("已发送验证信息，请注意查收");
        this.count_down((time) => {
          this.email_code_wait = time;
          this.InfoForm['email_token'] = data['token']
        }, 60);
      });
    },
    save() {
      //保存用户信息
      this.$refs['InfoForm'].validate((ret)=>{
          if(ret){//
            update_user_info(this.InfoForm).then(()=>{
                this.getUserInfo()
                this.$message.success("保存成功")
            })
          }
      })
    },
  },
  computed: {
    ...mapState("auth", ["user_info"]),
    EmailBtnText() {
      if (this.email_code_wait > 0) {
        return `等待${this.email_code_wait}秒`;
      } else {
        return "发送验证码";
      }
    },
    ShowEmailCode() {
      return this.user_info["email"] !== this.InfoForm["email"];
    },
    rules() {
      var CheckPasswd = (rule, value, callback) => {
        if (value) {
          var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入6-12位数字字母组合!"));
          }
        }
        callback();
      };
      var CheckEmailCode = (rule, value, callback) => {
        if (this.ShowEmailCode) {
          const reg = /^\d{6}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入6数字"));
          }
        }
        callback();
      };
      
      return {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        passWd: [{ validator: CheckPasswd, trigger: "blur" }],
        email_code:[{ validator: CheckEmailCode, trigger: "blur" }],
      };
    },
  },
  mounted() {
    this.getUserInfo().then((resp) => {
      this.loading = false;
      for (let k in this.InfoForm) {
        this.InfoForm[k] = resp[k];
      }
    });
  },
};
</script>

<style lang="stylus" scoped>
.update-user {
  display: block;
  box-sizing: border-box;

  .avatar {
    display: block;
    margin: 20px auto;
  }

  .save-btn {
    display: block;
    width: 100%;
  }
}
</style>